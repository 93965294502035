import React, { useRef, useState, useEffect } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import style from './styles.css';

interface SearchProps {
    searchTermValue: string;
    setSearchTermHandler: (value: string) => void;
}

const SearchForm: React.FC<SearchProps> = ({ searchTermValue, setSearchTermHandler }) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTermValue);
    const minQueryLength = 2;

    useEffect(() => {
        const trimmedSearchTerm = localSearchTerm.trim();
        if (
            trimmedSearchTerm.length > minQueryLength &&
            trimmedSearchTerm !== searchTermValue
        ) {
            setSearchTermHandler(trimmedSearchTerm);
        } else if (trimmedSearchTerm.length === 0 && searchTermValue !== '') {
            setSearchTermHandler('');
        }
    }, [localSearchTerm, searchTermValue, setSearchTermHandler]);

    const t = usePhraseTranslater();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (searchTermValue !== localSearchTerm) {
            setSearchTermHandler(localSearchTerm);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalSearchTerm(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            setLocalSearchTerm('');
        }
    };

    return (
        <form
            className={classes('form logo_mini_search logo_searchform', style.searchForm)}
            onSubmit={handleSubmit}
            acceptCharset="utf-8"
        >
            <div className={style.searchBox}>
                <input
                    id="search"
                    type="text"
                    name="w"
                    ref={searchInputRef}
                    value={localSearchTerm}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className={style.searchInput}
                    placeholder={t('Search')}
                />
                <button type="submit" className={style.submitButton}>
                    <i className="fas fa-search" />
                </button>
            </div>
        </form>
    );
};

export default SearchForm;
