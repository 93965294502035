import React from 'react';
import DigitizedLogos from 'ui/component/digitized-logos/digitized-logos';
import styles from '../my-custom-products-page/style.css';
import { MyDigitizedLogoPageLayout } from '../layouts/my-digitized-logo-page-layout';

const MyDigitizedLogoPage = () => (
    <div data-page-type="custom-logo-list">
        <div className={styles.myProductList}>
            <MyDigitizedLogoPageLayout showSidebar>
                <DigitizedLogos />
            </MyDigitizedLogoPageLayout>
        </div>
    </div>
);

export { MyDigitizedLogoPage as default };
