import React from 'react';
import outline from 'ui/styles/outline-effect.css';
import { classes } from '@silkpwa/module/util/classes';
import { computeWidthStyle } from 'ui/util/compute-width';

import styles from './styles.css';

interface CProps {
    handleClick: (id: number) => void;
    id: number;
    children?: React.ReactNode;
    displayed?: number;
}

const CardBodyWrapper: React.FC<CProps> = ({
    handleClick,
    id,
    children,
    displayed = 4,
}) => (
    <div
        className={styles.boxWrap}
        style={computeWidthStyle(displayed)}
    >
        <div
            className={classes(styles.box, outline.outlineOff)}
            data-test="customer-logo-box"
            role="button"
            tabIndex={0}
            onClick={() => handleClick(id)}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    handleClick(id);
                }
            }}
        >
            {children}
        </div>
    </div>
);

export default CardBodyWrapper;
