import React, { createContext, useState } from 'react';
import { DigitizedLogo } from 'ui/util/type-helper';
import Popup from 'ui/component/popup/popup';
import CardBodyWrapper from './card-body/card-body-wrapper';
import CardBody from './card-body/card-body';
import PopupCard from './popup-card/popup-card';

const DisplayedContext = createContext<number>(4);

interface DLProps {
    logosData: DigitizedLogo[];
    show?: number;
}

const DigitizedLogosList: React.FC<DLProps> = ({ logosData, show = 4 }) => {
    const [popupId, setPopupId] = useState<null|number>(null);

    const handleClosePopup = () => {
        setPopupId(null);
    };

    const handleOnClick = (id: number) => {
        setPopupId(id);
    };

    return (
        <>
            {logosData && logosData.map((logo: DigitizedLogo, id) => (
                <>
                    <DisplayedContext.Provider value={show}>
                        <CardBodyWrapper displayed={show} handleClick={handleOnClick} id={id}>
                            <CardBody logo={logo} key={logo?.image_url} displayed={1} />
                        </CardBodyWrapper>
                    </DisplayedContext.Provider>
                    <Popup isVisible={id === popupId} onClose={handleClosePopup}>
                        <PopupCard
                            imgUrl={logo?.image_url}
                            alt={logo?.alt}
                            logo={logo}
                        />
                    </Popup>
                </>
            ))}
        </>
    );
};
export default DigitizedLogosList;
