import React from 'react';
import { DigitizedLogo } from 'ui/util/type-helper';
import { generateUniqueKey } from 'ui/util/validator-helper';

import style from './style.css';

interface PPCard {
    imgUrl: string;
    alt: string;
    logo: DigitizedLogo;
}

const PopupCard: React.FC<PPCard> = ({
    imgUrl,
    alt,
    logo,
}) => (
    <div className={style.popupCardCnt}>
        <div className={style.mainPopCnt}>
            <img
                src={imgUrl}
                width={240}
                height="auto"
                alt={alt}
                className={style.imgPopCnt}
            />
        </div>
        <div className={style.mainPopCnt}>
            <fieldset className={style.popupCardFieldsetCnt}>
                <legend>Design Details</legend>
                {logo?.logo_number && (
                    <div>
                        <strong className={style.labelCnt}>Design Number:</strong>
                        <span className={style.valueCnt}>{logo.logo_number}</span>
                    </div>
                )}
                {logo?.design_description && (
                    <div>
                        <strong className={style.labelCnt}>Design Description:</strong>
                        <span className={style.valueCnt}>{logo.design_description}</span>
                    </div>
                )}
                {logo?.stitch_count && (
                    <div>
                        <strong className={style.labelCnt}>Stitch Count:</strong>
                        <span className={style.valueCnt}>{logo.stitch_count}</span>
                    </div>
                )}
                {logo?.height && (
                    <div>
                        <strong className={style.labelCnt}>Height:</strong>
                        <span className={style.valueCnt}>{logo.height}</span>
                    </div>
                )}
                {logo?.width && (
                    <div>
                        <strong className={style.labelCnt}>Width:</strong>
                        <span className={style.valueCnt}>{logo.width}</span>
                    </div>
                )}
                {logo?.trims && (
                    <div>
                        <strong className={style.labelCnt}>Trims:</strong>
                        <span className={style.valueCnt}>{logo.trims}</span>
                    </div>
                )}
            </fieldset>
            {logo?.colors && logo?.colors.length > 0 && (
                <fieldset className={style.popupCardFieldsetCnt}>
                    <legend>Design Color</legend>
                    <div className={style.gridCnt}>
                        {logo?.colors && logo.colors.map((color, i) => (
                            <div
                                key={generateUniqueKey(i)}
                                style={{ backgroundColor: color?.hash_code }}
                                className={style.gridColorCnt}
                            >
                                {color?.color}
                            </div>
                        ))}
                    </div>
                </fieldset>
            )}
        </div>
    </div>
);

export default PopupCard;
