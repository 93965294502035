import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_LOGOS } from 'graphql/customer';
import { LoadingImage } from 'ui/component/loading-image';
import { DigitizedLogo, DigitizedLogoData } from 'ui/util/type-helper';
import screenSwitch from 'ui/styles/screen-switch.css';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import DigitizedLogosList from './digitized-logos-list';
import Navbar from './navbar/navbar';
import styles from './style.css';

const sortOptionArray = [
    {
        id: 'CREATED_AT_DESC',
        value: 'CREATED_AT_DESC',
        label: 'New to Old',
    },
    {
        id: 'CREATED_AT_ASC',
        value: 'CREATED_AT_ASC',
        label: 'Old to New',
    },
    {
        id: 'DESCRIPTION_DESC',
        value: 'DESCRIPTION_DESC',
        label: 'Logo Name - Z to A',
    },
    {
        id: 'DESCRIPTION_ASC',
        value: 'DESCRIPTION_ASC',
        label: 'Logo Name - A to Z',
    },
];

const DigitizedLogos = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [sortedBy, setSortedBy] = useState<string>('CREATED_AT_DESC');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [logos, setLogos] = useState<DigitizedLogo[]>([]);
    const {
        loading, data, refetch,
    } = useQuery<DigitizedLogoData>(GET_CUSTOMER_LOGOS, {
        variables: {
            orderBy: sortedBy,
            search: searchTerm,
            pageSize: 12,
            currentPage,
        },
    });

    const t = usePhraseTranslater();

    const handleSortSelection = (value: string) => {
        setCurrentPage(_ => 1);
        setSortedBy(_ => value);
    };

    const setSearchTermHandler = (searchString: string) => {
        setCurrentPage(_ => 1);
        setSearchTerm(searchString);
    };

    useEffect(() => {
        if (data?.getCustomerLogo?.logo) {
            if (currentPage > 1) {
                setLogos(prevPosts => [...prevPosts, ...data.getCustomerLogo.logo]);
            } else {
                setLogos(_ => [...data.getCustomerLogo.logo]);
            }
        }
        if (data?.getCustomerLogo.page_info?.total_pages) {
            setTotalPage(data.getCustomerLogo.page_info?.total_pages);
        }
    }, [data]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 &&
            !loading &&
            currentPage < totalPage
        ) {
            refetch({
                currentPage: currentPage + 1,
            });
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, loading, totalPage]);

    useEffect(() => {
        const onScroll = () => handleScroll();
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [handleScroll]);

    if (loading) {
        return (
            <div className={styles.smallLoadingImage}>
                <LoadingImage />
            </div>
        );
    }

    return (
        <>
            <Navbar
                heading={t('Customer Logos')}
                sortOptions={sortOptionArray}
                handleSortSelection={handleSortSelection}
                sortedBy={sortedBy}
                setSearchTermHandler={setSearchTermHandler}
                searchTermValue={searchTerm}
            />
            { logos && (
                <>
                    <div className={screenSwitch.showOnDesktop}>
                        <DigitizedLogosList logosData={logos} show={4} />
                    </div>
                    <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                        <DigitizedLogosList logosData={logos} show={3} />
                    </div>
                    <div className={screenSwitch.showOnSmallMobile}>
                        <DigitizedLogosList logosData={logos} show={2} />
                    </div>
                    <div className={screenSwitch.showOnVerySmallMobile}>
                        <DigitizedLogosList logosData={logos} show={2} />
                    </div>
                </>
            )}
        </>
    );
};

export default DigitizedLogos;
