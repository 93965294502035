import React from 'react';
import { LazyLoadComponent } from 'ui/component/lazy-load';
import { computeWidthStyle } from 'ui/util/compute-width';
import { DigitizedLogo } from 'ui/util/type-helper';

import styles from './styles.css';

interface CProps {
    logo: DigitizedLogo;
    displayed: number;
    key?: number|string;
}

const CardBody: React.FC<CProps> = ({
    logo,
    displayed,
}) => (
    <>
        <div className={styles.bodyCard} style={computeWidthStyle(displayed)}>
            <div className={styles.bodyCardWrap}>
                <div data-test="logo-item" data-sku={logo?.title} className={styles.imageWrap}>
                    <LazyLoadComponent>
                        <div className={styles.imageContainer}>
                            <img
                                src={logo?.image_url}
                                alt={logo?.alt || logo?.title || ''}
                                width="100"
                                height="150"
                            />
                        </div>
                    </LazyLoadComponent>
                </div>
                <div className={styles.bodyCardName}>
                    {logo?.title}
                </div>
            </div>
        </div>
    </>
);
export default CardBody;
