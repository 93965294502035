import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { MainColumn } from 'ui/component/main-column';
import { classes } from '@silkpwa/module/util/classes';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { LeftSidebar } from 'ui/page/sales-pages/layouts/account-layout/left-sidebar';
import styles from './style.css';

interface IMyCustomProductsLayoutProps {
    children: React.ReactNode;
    showSidebar?: boolean;
}

declare global {
    interface Window {
        isSessionInitialized?: boolean;
    }
}

window.isSessionInitialized = false;

export const MyDigitizedLogoPageLayout: React.FC<IMyCustomProductsLayoutProps> = ({ children, showSidebar }) => {
    const t = usePhraseTranslater();

    return (
        <MainColumn className={styles.myCustomProductsLayout}>
            <DocumentTitle>{t('My Digitized logos')}</DocumentTitle>
            <ApolloProvider client={GraphQlClient}>
                { showSidebar && <LeftSidebar /> }
                <div className={classes(styles.layoutContentCustomerLogo,
                        styles.layoutContent,
                        { [styles.fullWidth]: !showSidebar })}
                >
                    <div className={styles.mainContent}>
                        {children}
                    </div>
                </div>
            </ApolloProvider>
        </MainColumn>
    );
};
