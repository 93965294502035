import React from 'react';
import { DropDown } from 'ui/component/drop-down';
import { classes } from '@silkpwa/module/util/classes';
import SearchForm from '../search-form/search-form';

import styles from './styles.css';

interface OptionProps {
    id: string;
    value: string;
    label: string;
}

interface NProps {
    heading: string;
    searchTermValue: string;
    sortOptions: OptionProps[];
    handleSortSelection: (value: string) => void;
    setSearchTermHandler: (value: string) => void;
    sortedBy?: string;
}

const Navbar: React.FC<NProps> = ({
    heading,
    searchTermValue,
    sortOptions,
    sortedBy,
    setSearchTermHandler,
    handleSortSelection,
}) => (
    <div className={styles.navCnt}>
        <div className={classes(styles.headingCnt, styles.navItem)}>
            <span>{heading}</span>
        </div>
        <div className={classes(styles.searchFormCnt, styles.navItem)}>
            <SearchForm searchTermValue={searchTermValue} setSearchTermHandler={setSearchTermHandler} />
        </div>
        <div className={classes(styles.sortByCnt, styles.navItem)}>
            <DropDown
                label=""
                options={sortOptions}
                selected={sortedBy}
                handleSelect={(option: { id: string }) => {
                    handleSortSelection(option.id);
                }}
            />
        </div>
    </div>
);

export default Navbar;
